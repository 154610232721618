[type='range'] {
  margin: 0;
  padding: 0;
  width: 12.5em;
  height: 1.5em;
  background: transparent;
  font: 1em/1 arial, sans-serif;
}

[type='range'], [type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
}

[type='range']::-webkit-slider-runnable-track {
  box-sizing: border-box;
  border: none;
  width: 12.5em;
  height: 0.25em;
  background: #ccc;
}

[type='range']::-moz-range-track {
  box-sizing: border-box;
  border: none;
  width: 12.5em;
  height: 0.25em;
  background: #ccc;
}

[type='range']::-ms-track {
  box-sizing: border-box;
  border: none;
  width: 12.5em;
  height: 0.25em;
  background: #ccc;
}

[type='range']::-webkit-slider-thumb {
  margin-top: -0.625em;
  box-sizing: border-box;
  border: none;
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  background: #465664;
}

[type='range']::-moz-range-thumb {
  box-sizing: border-box;
  border: none;
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  background: #465664;
}

[type='range']::-ms-thumb {
  margin-top: 0;
  box-sizing: border-box;
  border: none;
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  background: #465664;
}

[type='range']::-ms-tooltip {
  display: none;
}
