$track-w: 12.5em;
$track-h: .25em;
$thumb-d: 1.5em;

@mixin track() {
	box-sizing: border-box;
	border: none;
	width: $track-w; height: $track-h;
	background: #ccc;
}

@mixin thumb() {
	box-sizing: border-box;
	border: none;
	width: $thumb-d; height: $thumb-d;
	border-radius: 50%;
	background: #465664;
}

[type='range'] {
	&, &::-webkit-slider-thumb {
		-webkit-appearance: none;
	}

	margin: 0;
	padding: 0;
	width: $track-w; height: $thumb-d;
	background: transparent;
	font: 1em/1 arial, sans-serif;

	&::-webkit-slider-runnable-track {
		@include track
	}
	&::-moz-range-track { @include track }
	&::-ms-track { @include track }

	&::-webkit-slider-thumb {
		margin-top: .5*($track-h - $thumb-d);
		@include thumb
	}
	&::-moz-range-thumb { @include thumb }
	&::-ms-thumb {
		margin-top: 0;
		@include thumb
	}

	&::-ms-tooltip { display: none }
}
